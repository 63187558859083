<template>
  <div class="grid-container">
    <div class="grid-x grid-padding-x">
      <div class="cell small-12 medium-10">
        <h1 class="pb-5">
          Client record for: {{ client.name }}
        </h1>
      </div>
      <div class="cell small-8 medium-2">
        <router-link :to="`/clients/edit/${client.id}`">
            <button class="button expanded">Edit client</button>
          </router-link>
      </div>
    </div>
    <div class="grid-x grid-padding-x">
      <div class="cell small-12">
        <p>Telephone: {{ client.telephone }}</p>
        <div v-for="(invoice_parameter, index) in client.invoice_parameters" :key="`ip_${index}`">
          <p v-if="client.invoice_parameters[0]">
            Invoice email: {{ invoice_parameter.email }}
          </p>
          <p v-if="client.invoice_parameters[0]">
            Invoice currency: {{ invoice_parameter.currency }}
          </p>
        </div>
      </div>
    </div>
    <div class="grid-x grid-padding-x pt-5">
      <div class="cell small-12">
        <h4>Account Handlers</h4>
        <ul class="simple-list">
          <li
            v-for="(user, index) in client.users"
            :key="index">
            {{ user.first_name }} {{ user.last_name }}
          </li>
        </ul>
      </div>
    </div>
    <div class="grid-x grid-padding-x pt-5">
      <div class="cell small-12">
        <h4>Contacts</h4>
        <ul class="simple-list">
          <li
            v-for="(contact, index) in client.contacts"
            :key="index">
            {{ contact.first_name }} {{ contact.last_name }}
            <span v-if="contact.email">&nbsp;Email: {{ contact.email }}</span>
            <span v-if="contact.telephone">&nbsp;Telephone: {{ contact.telephone }}</span>
            <span v-if="contact.telephone">&nbsp;Title: {{ contact.title }}</span>
            <span v-if="contact.telephone">&nbsp;Type: {{ contact.type }}</span>
          </li>
        </ul>
      </div>
    </div>
    <div class="grid-x grid-padding-x pt-5">
      <div class="cell small-12">
        <h4>Brands</h4>
        <ul class="simple-list">
          <li
            v-for="(brand, index) in client.brands"
            :key="index">
            {{ brand.name }}
          </li>
        </ul>
      </div>
    </div>
    <div class="grid-x grid-padding-x pt-5">
      <div class="cell small-12">
        <h4>Veeva information</h4>
        <p
            v-for="(veeva, index) in client.veeva_accounts"
            :key="index">
            Username: {{ veeva.username }}<br />
            Password: {{ veeva.password }}
        </p>
      </div>
    </div>
    <div class="grid-x grid-padding-x pt-5">
      <div class="cell small-12">
        <h4>Ratecard</h4>
        <p
            v-for="(ratecard, index) in client.rate_cards"
            :key="`rc_${index}`">
            Description: {{ ratecard.description }}
            Brand: {{ ratecard.brand.name }}
            <router-link :to="`/clients/ratecard/${client.id}`">
              View ratecard
            </router-link>
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import axios from '../../axios';

export default {
  name: 'ClientsView',
  computed: {
    token() {
      return this.$store.state.token;
    },
  },
  data() {
    return {
      client: {},
    };
  },
  methods: {
    getClient() {
      const clientId = this.$route.params.id;
      axios.get(`/clients/get/${clientId}.json?token=${this.token}`)
        .then((response) => {
          this.client = response.data.client;
        })
        .catch(() => {
          this.failed = true;
        });
    },
  },
  mounted() {
    setTimeout(() => {
      this.getClient();
    }, 100);
  },
};
</script>
